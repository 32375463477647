@font-face {
  font-family: 'RelativeBook';
  src: local('RelativeBook'),
    url('../assets/relative-book-500.woff') format('woff');
  font-weight: 500;
}

@font-face {
  font-family: 'RelativeBook';
  src: local('RelativeBook'),
    url('../assets/relative-book-300.woff') format('woff');
  font-weight: 300;
}

@font-face {
  font-family: 'RelativeBook';
  src: local('RelativeBook'),
    url('../assets/relative-book-700.woff') format('woff');
  font-weight: 700;
}
